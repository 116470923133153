// Utility functions for managing watchlist and playlist in localStorage

// Get watchlist from localStorage
export const getWatchlist = () => {
  const watchlist = localStorage.getItem('watchlist');
  return watchlist ? JSON.parse(watchlist) : [];
};

// Add item to watchlist
export const addToWatchlist = (movie) => {
  const watchlist = getWatchlist();
  // Check if movie already exists in watchlist
  if (!watchlist.some(item => item.id === movie.id)) {
    watchlist.push(movie);
    localStorage.setItem('watchlist', JSON.stringify(watchlist));
    return true;
  }
  return false;
};

// Remove item from watchlist
export const removeFromWatchlist = (movieId) => {
  const watchlist = getWatchlist();
  const updatedWatchlist = watchlist.filter(movie => movie.id !== movieId);
  localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
  return updatedWatchlist;
};

// Check if movie is in watchlist
export const isInWatchlist = (movieId) => {
  const watchlist = getWatchlist();
  return watchlist.some(movie => movie.id === movieId);
};

// Get playlist from localStorage
export const getPlaylist = () => {
  const playlist = localStorage.getItem('playlist');
  return playlist ? JSON.parse(playlist) : [];
};

// Add item to playlist
export const addToPlaylist = (movie) => {
  const playlist = getPlaylist();
  // Check if movie already exists in playlist
  if (!playlist.some(item => item.id === movie.id)) {
    playlist.push(movie);
    localStorage.setItem('playlist', JSON.stringify(playlist));
    return true;
  }
  return false;
};

// Remove item from playlist
export const removeFromPlaylist = (movieId) => {
  const playlist = getPlaylist();
  const updatedPlaylist = playlist.filter(movie => movie.id !== movieId);
  localStorage.setItem('playlist', JSON.stringify(updatedPlaylist));
  return updatedPlaylist;
};

// Check if movie is in playlist
export const isInPlaylist = (movieId) => {
  const playlist = getPlaylist();
  return playlist.some(movie => movie.id === movieId);
}; 