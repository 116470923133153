import React, { useEffect } from 'react';
import './ErrorBanner.scss';

/**
 * Error banner component to display technical difficulties message
 * @param {Object} props - Component props
 * @param {boolean} props.show - Whether to show the banner
 * @returns {JSX.Element|null} - Error banner component or null if not shown
 */
const ErrorBanner = ({ show }) => {
  useEffect(() => {
    if (show) {
      console.log('ErrorBanner is being shown');
      
      // Log to verify the component is mounted and show prop is true
      const logInterval = setInterval(() => {
        console.log('ErrorBanner is still visible');
      }, 5000);
      
      return () => clearInterval(logInterval);
    }
  }, [show]);
  
  if (!show) return null;
  
  return (
    <div className="error-banner">
      <div className="error-banner__content">
        <div className="error-banner__emoji">
          <span role="img" aria-label="Smutna twarz">😢</span>
        </div>
        <h3>Przepraszamy, mamy obecnie problemy techniczne.</h3>
        <p>Pracujemy nad rozwiązaniem problemu. Prosimy o cierpliwość.</p>
      </div>
    </div>
  );
};

export default ErrorBanner; 