import React, { useState } from 'react';
import ErrorBanner from './ErrorBanner';

/**
 * Test component to verify that the error banner works correctly
 * @returns {JSX.Element} - Test component
 */
const TestErrorBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  
  const toggleBanner = () => {
    setShowBanner(!showBanner);
  };
  
  return (
    <div style={{ 
      padding: '20px', 
      textAlign: 'center',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#111'
    }}>
      {showBanner && <ErrorBanner show={true} />}
      
      <button 
        onClick={toggleBanner}
        style={{
          padding: '15px 30px',
          backgroundColor: showBanner ? '#dc3545' : '#28a745',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '16px',
          fontWeight: 'bold',
          transition: 'all 0.3s ease'
        }}
      >
        {showBanner ? 'Hide Error Banner' : 'Show Error Banner'}
      </button>
      
      <p style={{ 
        marginTop: '20px',
        color: 'white',
        fontSize: '18px'
      }}>
        {showBanner 
          ? 'Error banner is currently visible with a sad face emoji and centered on the screen.' 
          : 'Click the button to show the error banner with a sad face emoji.'}
      </p>
    </div>
  );
};

export default TestErrorBanner; 