// Get API keys
const YT_API_KEY = process.env.REACT_APP_YOUTUBE_KEY;
const TMDB_API_KEY = process.env.REACT_APP_TMDB_KEY;

// Check environment using custom environment variable from .env
// This allows manual switching between production and development modes
const isCustomDevelopment = process.env.REACT_APP_CUSTOM_ENV === 'development';

// Define requests based on environment
const requests = isCustomDevelopment 
  ? {
      // TMDB API endpoints for development
      fetchSpecial: `https://api.themoviedb.org/3/movie/popular?api_key=${TMDB_API_KEY}&language=pl-PL&page=1`,
      fetchLatest: `https://api.themoviedb.org/3/movie/top_rated?api_key=${TMDB_API_KEY}&language=pl-PL&page=1`,
      fetchRandom: `https://api.themoviedb.org/3/trending/movie/week?api_key=${TMDB_API_KEY}&language=pl-PL`
    }
  : {
      // YouTube API endpoints for production
      fetchSpecial: `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100&playlistId=PLAgaDn19X_evTJXqdcZzkeY1F91hYsEMU&fields=items%2Fsnippet(title%2Cdescription%2Cthumbnails(standard%2Cmaxres)%2CresourceId(videoId))&key=${YT_API_KEY}`,
      fetchLatest: `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100&playlistId=PLAgaDn19X_ev_JMLCbQkDYlOXGbQOTRla&fields=items%2Fsnippet(title%2Cdescription%2Cthumbnails(standard%2Cmaxres)%2CresourceId(videoId))&key=${YT_API_KEY}`,
      fetchRandom: `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100&playlistId=PLAgaDn19X_etRzIRsivMKWLUN7TeToKl8&fields=items%2Fsnippet(title%2Cdescription%2Cthumbnails(standard%2Cmaxres)%2CresourceId(videoId))&key=${YT_API_KEY}`,
    };

export default requests;
