import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getWatchlist, getPlaylist, addToWatchlist, addToPlaylist, removeFromWatchlist, removeFromPlaylist, isInWatchlist, isInPlaylist } from './watchlistUtils';

// Create context
const WatchlistContext = createContext();

// Custom hook to use the watchlist context
export const useWatchlist = () => useContext(WatchlistContext);

// Provider component
export const WatchlistProvider = ({ children }) => {
  const [watchlist, setWatchlist] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [watchlistStatus, setWatchlistStatus] = useState({});
  const [playlistStatus, setPlaylistStatus] = useState({});

  // Update watchlist status based on current watchlist
  const updateWatchlistStatus = useCallback((watchlistData) => {
    const statusObj = {};
    watchlistData.forEach(item => {
      statusObj[item.id] = true;
    });
    setWatchlistStatus(statusObj);
  }, []);

  // Update playlist status based on current playlist
  const updatePlaylistStatus = useCallback((playlistData) => {
    const statusObj = {};
    playlistData.forEach(item => {
      statusObj[item.id] = true;
    });
    setPlaylistStatus(statusObj);
  }, []);

  // Refresh watchlist
  const refreshWatchlist = useCallback(() => {
    const watchlistData = getWatchlist();
    setWatchlist(watchlistData);
    updateWatchlistStatus(watchlistData);
    return watchlistData;
  }, [updateWatchlistStatus]);

  // Refresh playlist
  const refreshPlaylist = useCallback(() => {
    const playlistData = getPlaylist();
    setPlaylist(playlistData);
    updatePlaylistStatus(playlistData);
    return playlistData;
  }, [updatePlaylistStatus]);

  // Load initial data
  useEffect(() => {
    refreshWatchlist();
    refreshPlaylist();
  }, [refreshWatchlist, refreshPlaylist]);

  // Toggle item in watchlist (add if not present, remove if already present)
  const addToWatchlistWithRefresh = (movie) => {
    // Check if the movie is already in the watchlist
    if (isInWatchlist(movie.id)) {
      // If present, remove it
      const updatedWatchlist = removeFromWatchlist(movie.id);
      setWatchlist(updatedWatchlist);
      
      // Update status to reflect removal
      setWatchlistStatus(prev => {
        const newStatus = { ...prev };
        delete newStatus[movie.id];
        return newStatus;
      });
      
      return false; // Indicate item was removed
    } else {
      // If not present, add it
      const added = addToWatchlist(movie);
      if (added) {
        const watchlistData = getWatchlist();
        setWatchlist(watchlistData);
        
        // Direct update of status for efficiency
        setWatchlistStatus(prev => ({
          ...prev,
          [movie.id]: true
        }));
      }
      return added; // Indicate item was added
    }
  };

  // Remove from watchlist
  const removeFromWatchlistWithRefresh = (movieId) => {
    try {
      const updatedWatchlist = removeFromWatchlist(movieId);
      setWatchlist(updatedWatchlist);
      
      // Direct update of status for efficiency
      setWatchlistStatus(prev => {
        const newStatus = { ...prev };
        delete newStatus[movieId];
        return newStatus;
      });
      
      return updatedWatchlist;
    } catch (error) {
      console.error('Error removing from watchlist:', error);
      return getWatchlist();
    }
  };

  // Toggle item in playlist (add if not present, remove if already present)
  const addToPlaylistWithRefresh = (movie) => {
    // Check if the movie is already in the playlist
    if (isInPlaylist(movie.id)) {
      // If present, remove it
      const updatedPlaylist = removeFromPlaylist(movie.id);
      setPlaylist(updatedPlaylist);
      
      // Update status to reflect removal
      setPlaylistStatus(prev => {
        const newStatus = { ...prev };
        delete newStatus[movie.id];
        return newStatus;
      });
      
      return false; // Indicate item was removed
    } else {
      // If not present, add it
      const added = addToPlaylist(movie);
      if (added) {
        const playlistData = getPlaylist();
        setPlaylist(playlistData);
        
        // Direct update of status for efficiency
        setPlaylistStatus(prev => ({
          ...prev,
          [movie.id]: true
        }));
      }
      return added; // Indicate item was added
    }
  };

  // Remove from playlist
  const removeFromPlaylistWithRefresh = (movieId) => {
    try {
      const updatedPlaylist = removeFromPlaylist(movieId);
      setPlaylist(updatedPlaylist);
      
      // Direct update of status for efficiency
      setPlaylistStatus(prev => {
        const newStatus = { ...prev };
        delete newStatus[movieId];
        return newStatus;
      });
      
      return updatedPlaylist;
    } catch (error) {
      console.error('Error removing from playlist:', error);
      return getPlaylist();
    }
  };
  
  // Check if movie is in watchlist
  const checkInWatchlist = (movieId) => {
    return isInWatchlist(movieId);
  };

  // Check if movie is in playlist
  const checkInPlaylist = (movieId) => {
    return isInPlaylist(movieId);
  };

  // Update status for a list of movies
  const updateStatusForMovies = useCallback((movies) => {
    if (!movies || movies.length === 0) return;
    
    // Process movies once and determine what needs to be updated
    const updates = movies.reduce((acc, movie) => {
      if (!movie || !movie.id) return acc;
      
      const inWatchlist = isInWatchlist(movie.id);
      const inPlaylist = isInPlaylist(movie.id);
      
      // Check if this movie needs a watchlist status update
      if (!acc.watchlistMap[movie.id] || acc.watchlistMap[movie.id] !== inWatchlist) {
        acc.watchlistMap[movie.id] = inWatchlist;
        acc.watchlistChanged = true;
      }
      
      // Check if this movie needs a playlist status update
      if (!acc.playlistMap[movie.id] || acc.playlistMap[movie.id] !== inPlaylist) {
        acc.playlistMap[movie.id] = inPlaylist;
        acc.playlistChanged = true;
      }
      
      return acc;
    }, {
      watchlistMap: {},
      playlistMap: {},
      watchlistChanged: false,
      playlistChanged: false
    });
    
    // Update watchlist status if needed
    if (updates.watchlistChanged) {
      setWatchlistStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        
        // Apply all updates
        Object.entries(updates.watchlistMap).forEach(([id, isInList]) => {
          if (isInList) {
            newStatus[id] = true;
          } else if (newStatus[id]) {
            delete newStatus[id];
          }
        });
        
        return newStatus;
      });
    }
    
    // Update playlist status if needed
    if (updates.playlistChanged) {
      setPlaylistStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        
        // Apply all updates
        Object.entries(updates.playlistMap).forEach(([id, isInList]) => {
          if (isInList) {
            newStatus[id] = true;
          } else if (newStatus[id]) {
            delete newStatus[id];
          }
        });
        
        return newStatus;
      });
    }
  }, []);

  // Context value
  const value = {
    watchlist,
    playlist,
    watchlistStatus,
    playlistStatus,
    refreshWatchlist,
    refreshPlaylist,
    addToWatchlist: addToWatchlistWithRefresh,
    removeFromWatchlist: removeFromWatchlistWithRefresh,
    addToPlaylist: addToPlaylistWithRefresh,
    removeFromPlaylist: removeFromPlaylistWithRefresh,
    isInWatchlist: checkInWatchlist,
    isInPlaylist: checkInPlaylist,
    updateStatusForMovies
  };

  return (
    <WatchlistContext.Provider value={value}>
      {children}
    </WatchlistContext.Provider>
  );
}; 