import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import requests from "./requests";
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";
import { WatchlistProvider } from "./utils/WatchlistContext";
import TestErrorBanner from "./utils/TestErrorBanner";

const Nav = lazy(() => import("./Nav/Nav"));
const Banner = lazy(() => import("./Banner/Banner"));
const Row = lazy(() => import("./Row/Row"));
const Footer = lazy(() => import("./Footer/Footer"));
const MyList = lazy(() => import("./MyList/MyList"));
const Podcast = lazy(() => import("./Podcast/Podcast"));
const Author = lazy(() => import("./Author/Author"));
const Search = lazy(() => import("./Search/Search"));

function App() {
  return (
    <Router>
      <WatchlistProvider>
        <div className="app">
          <Suspense fallback={<LoadingAnimation />}>
            <Nav />
            <Switch>
              <Route exact path="/">
                <Banner fetchUrl={requests.fetchRandom} />
                <Row title="Najnowsze" fetchUrl={requests.fetchLatest} isLargeRow />
                <Row
                  title="Odcinki Specjalne"
                  fetchUrl={requests.fetchSpecial}
                  isLargeRow
                />
              </Route>
              <Route path="/my-list">
                <MyList />
              </Route>
              <Route path="/podcast">
                <Podcast />
              </Route>
              <Route path="/author">
                <Author />
              </Route>
              <Route path="/search">
                <Search />
              </Route>
              <Route path="/test-error">
                <TestErrorBanner />
              </Route>
            </Switch>
            <Footer></Footer>
          </Suspense>
        </div>
      </WatchlistProvider>
    </Router>
  );
}

export default App;
